<template>
  <q-layout view="hHh Lpr lff">
<!-- HEADER -->
    <q-header elevated >
      <q-toolbar class="home-toolbar">
        <q-btn flat @click="drawer = !drawer" round dense icon="menu" />
        <q-toolbar-title>Slotification</q-toolbar-title>
        <q-space />
        <q-btn flat @click="logout">Logout</q-btn>
      </q-toolbar>
    </q-header>

<!-- DRAWER -->
    <q-drawer v-model="drawer" show-if-above bordered :width="250" :breakpoint="500">

          <div class="column drawer-top items-center">
              <q-img class="col" src="../assets/logo.svg" style="width:150px"/>
              <div class="text-weight-bold drawer-top-text">{{user.first_name}} {{user.last_name}}</div>
              <div class="drawer-top-text">{{user.email}}</div>
          </div>
          <q-list padding class="home-menu-list">
            <q-separator/>
            <template v-for="(menuItem, index) in menuList" :key="index">
              <q-item clickable :active="menuItem.label === 'Outbox'" v-ripple :to="{path: menuItem.route}" class="home-menu-item" active-class="home-menu-item-active">
                <q-item-section avatar>
                  <q-icon :name="menuItem.icon" />
                </q-item-section>
                <q-item-section>
                  {{ menuItem.label }}
                </q-item-section>
              </q-item>
              <q-separator :key="'sep' + index"  v-if="menuItem.separator" />
            </template>

          </q-list>
      </q-drawer>
      <q-page-container>

        <slot/>

      </q-page-container>
  </q-layout>
</template>

<script>
import AuthService from '@/services/AuthService.js';
import { ref } from 'vue';
import {mapGetters} from 'vuex';

const menuList = [
	{
		icon: 'login',
		label: 'Automatisches Eintragen',
		route: '/join-slots',
		separator: true
	},
  {
    icon: 'notifications_active',
    label: 'Benachrichtigungen',
    route: '/',
    separator: false
  },
  {
    icon: 'storefront',
    label: 'Freie Slots',
    route: '/all-slots',
    separator: false
  },
  {
    icon: 'account_circle',
    label: 'Account',
    route: '/account',
    separator: false
  }
]

export default {
  data() {
    return {
      drawer: ref(false),
      menuList
    };
  },
  async created() {
    this.$q.addressbarColor.set();

    if (!this.$store.getters.isLoggedIn) {
      this.$store.dispatch('logout',{});
    }
    if (this.user === undefined){
      //get user data
      let localUser = await AuthService.getUser();
      localUser = localUser.data;
      this.$store.dispatch('setUser', localUser);
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('logout');
    }
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    })
  }
};
</script>
