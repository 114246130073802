<template>
  <default-layout>
    <div class="row justify-center q-mt-lg">
      <q-card class="account-card">
        <q-card-section>
          <div class="row">
            <div class="col-4 items-center">
              <q-avatar size="72px" class="q-mx-lg" icon="far fa-user" text-color="primary">
                <!--<img src="https://cambodiaict.net/wp-content/uploads/2019/12/computer-icons-user-profile-google-account-photos-icon-account.jpg">-->
              </q-avatar>
            </div>
            <div v-if="!editUserName" class="col-8">
              <div class="column justify-center full-height">
                <div class="col-auto account-card-text-big">{{user.first_name}} {{user.last_name}}</div>
              <div class="col-auto">{{user.email}}</div>
              </div>
            </div>
            <div v-else class="col-8 justify-center">
              <q-input dense v-model="firstName" label="Vorname"/>
              <q-input dense v-model="lastName" label="Nachname"/>
            </div>
          </div>
        </q-card-section>

        <q-separator />

        <q-card-actions vertical>
          <q-btn v-if="!editUserName" flat @click="editUserName = true">Bearbeiten</q-btn>
          <div v-else class="row justify-between full-width">
            <q-btn flat @click="editUserName = false">Abbrechen</q-btn>
            <q-btn flat @click="saveUserName">Speichern</q-btn>
          </div>
          
        </q-card-actions>
      </q-card>
      <q-card class="account-card">
        <q-card-section>
          <div class="row">
            <div class="col-4">
              <q-avatar size="48px" class="q-mx-lg" icon="fas fa-at" text-color="primary"/>
            </div>
            <div v-if="!editNotificationChannels" class="col-8 justify-center">
              <div class="col-auto account-card-text">E-Mail</div>
              <div class="col-auto">{{user.email != null ? user.email : "nicht angegeben"}}</div>
            </div>
            <div v-else class="col-8 justify-center">
              <q-input dense v-model="email" label="E-Mail"/>
            </div>
          </div>
        </q-card-section>

        <q-card-section>
          <div class="row">
            <div class="col-4">
              <div class="column justify-around full-height">
                  <q-avatar size="48px" class="q-mx-lg" icon="fab fa-whatsapp" text-color="primary"/>
              </div>

            </div>
            <div v-if="!editNotificationChannels" class="col-8 justify-center">
              <div class="col-auto account-card-text">WhatsApp
                <q-btn class="float-right" flat icon="help" style="padding:0" color="grey" @click="waHelp=true"/>
              </div>
              <div class="col-auto">Nr.: {{user.phone != null ? user.phone : "nicht angegeben"}}</div>
              <div class="col-auto">API-Key: {{user.waApiKey != null ? user.waApiKey : "nicht angegeben"}}</div>
            </div>
            <div v-else class="col-8 justify-center">
              <q-input dense v-model="phone" label="Telefon Nr." type="tel" mask="+## ##### ###########"/>
              <q-input dense v-model="waApiKey" label="API-Key"/>
            </div>
          </div>
        </q-card-section>

        <q-card-section>
          <div class="row">
            <div class="col-4">
              <q-avatar size="48px" class="q-mx-lg" icon="fab fa-twitter" text-color="primary"/>
            </div>
            <div v-if="!editNotificationChannels" class="col-8 justify-center">
              <div class="col-auto account-card-text">Twitter</div>
              <div class="col-auto">{{user.twitterName != null ? '@'+user.twitterName : "nicht angegeben"}}</div>
            </div>
            <div v-else class="col-8 justify-center">
              <q-input dense v-model="twitterName" prefix="@" label="Twitter"/>
            </div>
          </div>
        </q-card-section>

        <q-card-section>
          <div class="row">
            <div class="col-4">
              <q-avatar size="48px" class="q-mx-lg" icon="fab fa-discord" text-color="grey"/>
            </div>
            <div v-if="!editNotificationChannels" class="col-8 justify-center">
              <div class="col-auto account-card-text disabled">Discord</div>
              <div class="col-auto disabled">{{user.discord != null ? user.discord : "nicht verfügbar"}}</div>
            </div>
            <div v-else class="col-8 justify-center">
              <q-input dense disable v-model="discord" prefix="@" label="Discord"/>
            </div>
          </div>
        </q-card-section>

        <q-separator/>

        <q-card-actions vertical>
          <q-btn v-if="!editNotificationChannels" flat @click="editNotificationChannels = true">Bearbeiten</q-btn>
          <div v-else class="row justify-between full-width">
            <q-btn flat @click="editNotificationChannels = !editNotificationChannels">Abbrechen</q-btn>
            <q-btn flat @click="saveNotificationChannels">Speichern</q-btn>
          </div>
          
        </q-card-actions>
      </q-card>

      <foodsharing-connection-card class="account-card" 
        :user="user" 
        :checkingCredentials="checkingFoodsharingCredentials"
        @update="updateFoodsharingAccountData" 
        @reload-request="checkFoodsharingAccount"/>

      <q-card class="account-card">
        <q-card-actions vertical>
          <q-btn flat @click="resetPassword" text-color="negative">Passwort ändern</q-btn>
        </q-card-actions>
      </q-card>
    </div>

    <q-dialog v-model="dialog">
      <q-card>
        <q-card-section>
          <div class="text-h6">Passwort Änderung</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          Eine Email mit einem Link zum Ändern des Passworts wurde an die Emailadresse des Kontos versendet.
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="OK" color="primary" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <q-dialog v-model="waHelp">
      <q-card>
        <q-card-section>
          <div class="text-h6">WhatsApp einrichten</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          Die Kommunikation über WhatsApp muss für jede Telefonnummer freigeschaltet werden.<br>
          1. Füge die Nummer <a href="tel:+49 176 74521188">+49 167 74521188</a> zu deinen Kontakten hinzu<br>
          2. Schreibe eine WhatsApp-Nachricht mit dem Inhalt "/register" an diese Nummer.<br>
          3. Trage deine Nummer und den API-Key, der dir als Antwort auf deine erste Nachricht zugesendet wird, in die entsprechenden Felder ein.
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="OK" color="primary" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </default-layout>
</template>

<script>
// eslint-disable-next-line
  import AuthService from '@/services/AuthService.js'
  import HelperServices from '@/services/HelperServices.js'
  import DefaultLayout from './DefaultLayout.vue'
  import {mapGetters} from 'vuex';
  import FoodsharingConnectionCard from '../components/FoodsharingConnectionCard.vue';
  // @ is an alias to /src

  export default {
    name: 'Account',
    data() {
      return {
        editUserName: false,
        editNotificationChannels: false,
        checkingFoodsharingCredentials: false,
        firstName: 'Max',
        lastName: 'Mustermann',
        email: 'max@mustermail.de',
        twitterName: '@MaxM',
        discord: '12345',
        phone: '+49123456',
        waApiKey: '76483',
        dialog: false,
        waHelp: false
      }
    },
    components: {
      DefaultLayout,
      FoodsharingConnectionCard
    },
    async created() {
      if (!this.$store.getters.isLoggedIn) {
        this.$store.dispatch('logout',{});
      }
      this.firstName = this.user.first_name;
      this.lastName = this.user.last_name;
      this.email = this.user.email;
      this.twitterName = this.user.twitterName;
      this.discord = this.user.discord;
      this.phone = this.user.phone;
      this.waApiKey = this.user.waApiKey;
    },
    methods: {
      async saveNotificationChannels() {
        let payload = {};
        if (this.email !== this.user.email){
          payload.email = this.email;
        }
        if (this.twitterName !== this.user.twitterName){
          payload.twitterName = this.twitterName;
          HelperServices.updateTwitterId(this.user.id);
        }
        if(this.discord !== this.user.discord){
          payload.discord = this.discord;
        }
        if(this.phone !== this.user.phone){
          payload.phone = this.phone;
        }
        if(this.waApiKey !== this.user.waApiKey){
          payload.waApiKey = this.waApiKey;
        }

        if (Object.keys(payload).length != 0){
          await AuthService.updateUser(this.user.id, payload);
        }

        this.reloadUser();
        this.editNotificationChannels = false;
      },
      async saveUserName() {
        let payload = {};
        if (this.firstName !== this.user.first_name){
          payload.first_name = this.firstName;
        }
        if(this.lastName !== this.user.last_name){
          payload.last_name = this.lastName;
        }

        if (Object.keys(payload).length != 0){
          await AuthService.updateUser(this.user.id, payload);
        }

        this.reloadUser();
        this.editUserName = false;
      },

      async updateFoodsharingAccountData(payload){
        if (await AuthService.updateUser(this.user.id, payload)) {
          this.reloadUser();
          this.checkFoodsharingAccount();
        }
      },

      async checkFoodsharingAccount(){
        this.checkingFoodsharingCredentials = true;
        var isConnected = await HelperServices.checkFoodsharingCredentials(this.user.foodsharing_email, this.user.id);
        this.$store.dispatch('setUserIsFoodsharingConnected', {isConnected});
        this.checkingFoodsharingCredentials = false;
      },

      logout(){
       this.$store.dispatch('logout',{});
      },
      async reloadUser(){
        let user = await AuthService.getUser();
        user = user.data;
        this.$store.dispatch('setUser', {user});
      },
      async resetPassword(){
        await AuthService.requestPassword(this.user.email);
        this.dialog = true;
      }
    },
    computed: {
      ...mapGetters({
      user: 'getUser'
    })
    }
  }
</script>
