<template>
  <div class="login-background">
    <q-card class="login-card fixed-center">
      <q-form v-show="!isConnectionProblem" @submit="onSubmit">
        <q-card-section>
          <p class="title" :class="!isValidLogin ? 'login-error':''">Slotification</p>
          <p class="subtitle" :class="!isValidLogin ? 'login-error':''">Login</p>
        </q-card-section>
        <q-card-section>
          <q-input outlined bottom-slots v-model="username" label="Username">
            <template v-slot:prepend>
              <q-icon name="person" />
            </template>
          </q-input>
          <q-input
            outlined
            bottom-slots
            v-model="password"
            :type="isPwd ? 'password' : 'text'"
            label="Passwort"
          >
            <template v-slot:prepend>
              <q-icon name="key" />
            </template>
            <template v-slot:append>
              <q-icon
                :name="isPwd ? 'visibility_off' : 'visibility'"
                class="cursor-pointer"
                @click="isPwd = !isPwd"
              />
            </template>
          </q-input>
          <div v-show="!isValidLogin" class="login-error text-center">
            <b>Login fehlgeschlagen!</b>
          </div>
        </q-card-section>
        <q-card-actions class="justify-end">
          <q-btn
            flat
            :color="!isValidLogin ? 'negative' : 'primary'"
            type="submit"
            :disabled="password.length <= 4 || username.length == 0"
          >
            Login
          </q-btn>
        </q-card-actions>
      </q-form>
      <div v-show="isConnectionProblem">
        <p class="title">Slotification</p>
        <p class="subtitle">Verbindungsproblem</p>
        <p class="text-center">Es scheint ein Problem mit dem Server zu geben. <br><br>Bitte kontaktiere den Administrator.</p>
      </div>
    </q-card>
  </div>
</template>

<script>
  import AuthService from '@/services/AuthService.js'
  // @ is an alias to /src

  export default {
    name: 'Login',
    data() {
      return {
        username: '',
        password: '',
        isPwd: true,
        isValidLogin: true,
        isConnectionProblem: false
      }
    },
    components: {},
    async created() {
      const ping = await AuthService.pingServer();
      if (!ping){
        this.isConnectionProblem = true;
      }
      if(this.$store.getters.isLoggedIn){
        this.$router.push('/');
      }
    },
    methods: {
      async onSubmit() {
        this.isValidLogin = true;
        try {
          const credentials = {
            email: this.username,
            password: this.password,
          }
          const response = await AuthService.login(credentials)
          const access_token = response.data.access_token
          const refresh_token = response.data.refresh_token
          //const user = response.user
          this.$store.dispatch('login', { access_token, refresh_token })

          //get user data
          let user = await AuthService.getUser();
          user = user.data;
          this.$store.dispatch('setUser', {user});

          this.$router.push('/');
        } catch (error) {
          console.log(error)
          this.showError();
        }
      },
      logout(){
       this.$store.dispatch('logout',{});
      },
      showError(){
        this.isValidLogin = false;
      }
    },
  }
</script>
