<template>
	<DialogBase
		:title="isNewJoinRequest ? 'Join Request erstellen' : 'Join Request bearbeiten'"
		:showDialog="show"
		@clicked:save="saveClicked()"
		@clicked:abort="abortClicked"
		@clicked:delete="deleteClicked"
		:has-delete="!isNewJoinRequest">
		<div class="q-gutter-md">
			<q-input outlined v-model="newJoinRequest.storeId" type="number" label="Store ID" :error="!isValidStoreId" error-message="Bitte gib eine gültige, numerische ID ein"/>
			<q-toggle
				class="full-width justify-center"
				v-model="newJoinRequest.is_pickup_date_weekday"
				label="Betrachte Start- bis Enddatum als Wochentage:"
				left-label/>
			<q-input filled v-model="newJoinRequest.pickup_date_start" mask="##.##.####" label="Start Datum">
				<template v-slot:append>
					<q-icon name="event" class="cursor-pointer">
						<q-popup-proxy ref="qDateProxy" cover transition-show="scale" transition-hide="scale">
							<q-date v-model="newJoinRequest.pickup_date_start" mask="DD.MM.YYYY">
								<div class="row items-center justify-end">
									<q-btn v-close-popup label="Close" color="primary" flat />
								</div>
							</q-date>
						</q-popup-proxy>
					</q-icon>
				</template>
			</q-input>
			<q-input filled v-model="newJoinRequest.pickup_date_end" mask="##.##.####" label="End Datum">
				<template v-slot:append>
					<q-icon name="event" class="cursor-pointer">
						<q-popup-proxy ref="qDateProxy" cover transition-show="scale" transition-hide="scale">
							<q-date v-model="newJoinRequest.pickup_date_end" mask="DD.MM.YYYY">
								<div class="row items-center justify-end">
									<q-btn v-close-popup label="Close" color="primary" flat />
								</div>
							</q-date>
						</q-popup-proxy>
					</q-icon>
				</template>
			</q-input>

			<q-select outlined v-model="newJoinRequest.notification_channel" :options="notificationChannelList" label="Benachrichtigungsart" />

			<q-toggle
				class="full-width justify-center"
				v-model="newJoinRequest.is_active"
				label="Aktiv:"
				left-label
			/>
		</div>
	</DialogBase>
</template>

<script>
import DialogBase from './DialogBase.vue';
import { date } from 'quasar';

export default {
	name: 'JoinRequestDialog',
	props: {
		joinRequest: {
			type: Object,
			default: null
		},
		showDialog: {
			type: Boolean,
			default: false
		}
	},

	emits: ['update:showDialog', 'clicked:save', 'clicked:abort', 'clicked:delete'],

	components: {
		DialogBase
	},

	data() {
		return {
			isSaving: false,
			newJoinRequest: {
				id: 0,
				storeId: null,
				notification_channel: { label: 'WhatsApp', value: 'whatsapp' },
				notify_when_joined: true,
				pickup_date_start: date.formatDate(Date.now(), 'DD.MM.YYYY'),
				pickup_date_end: date.formatDate(Date.now(), 'DD.MM.YYYY'),
				is_pickup_date_weekday: true,
				is_active: true,
			},
			notificationChannelList:[{ label: 'E-Mail', value: 'email' }, { label: 'WhatsApp', value: 'whatsapp' }, { label: 'Keine Benachrichtigung', value: 'none' }]
		}
	},

	created() {
		if (this.joinRequest !== null && this.joinRequest !== undefined && Object.keys(this.joinRequest).length > 0) {
			this.newJoinRequest = {...this.joinRequest};
			this.newJoinRequest.notification_channel = this.notificationChannelList.find(x => x.value === this.joinRequest.notification_channel);
			this.newJoinRequest.pickup_date_start = date.formatDate(this.joinRequest.pickup_date_start, 'DD.MM.YYYY');
			this.newJoinRequest.pickup_date_end = date.formatDate(this.joinRequest.pickup_date_end, 'DD.MM.YYYY');
		}
	},

	methods: {
		saveClicked() {
			const dmyStart = this.newJoinRequest.pickup_date_start.split(".");
      const dmyEnd = this.newJoinRequest.pickup_date_end.split(".");

			this.$emit('clicked:save', { 
				id: this.newJoinRequest.id,
				storeId: this.newJoinRequest.storeId, 
				pickup_date_start: new Date(dmyStart[2], dmyStart[1] - 1, dmyStart[0], 12), 
				pickup_date_end: new Date(dmyEnd[2], dmyEnd[1] - 1, dmyEnd[0], 12), 
				is_pickup_date_weekday: this.newJoinRequest.is_pickup_date_weekday, 
				notification_channel: this.newJoinRequest.notification_channel.value, 
				notify_when_joined: this.newJoinRequest.notification_channel.value !== 'none', 
				is_active: this.newJoinRequest.is_active });
		},
		abortClicked() {
			this.show = false;
			this.$emit('clicked:abort');
		},
		deleteClicked() {
			this.$emit('clicked:delete', this.joinRequest);
		},
	},

	computed: {
    show: {
      get () {
        return this.showDialog
      },
      set (showDialog) {
				this.$emit('update:showDialog', showDialog)
      }
    },
		isNewJoinRequest() {
			return this.joinRequest === null || this.joinRequest === undefined || Object.keys(this.joinRequest).length === 0 ;
		},
		isValidStoreId(){
			return this.newJoinRequest.storeId !== null && this.newJoinRequest.storeId !== undefined && this.newJoinRequest.storeId > 0;
		},
	}
}
</script>