import { createStore } from 'vuex'
import Axios from 'axios';
import createPersistedState from 'vuex-persistedstate';
import router from '../router';


const getDefaultState = () => {
  return {
    access_token: '',
    refresh_token: '',
    user: {},
    notifications: [],
    joinRequests: [],
    stores: [],
    slots: []
  };
};

export default createStore({
  strict: true,
  plugins: [createPersistedState()],
  state: getDefaultState(),
  getters: {
    isLoggedIn: state => {
      return !(state.access_token === undefined || state.access_token === "");
    },
    getUser: state => {
      return state.user;
    },
    getToken: state => {
      return state.access_token;
    },
    getNotifications: state => {
      return state.notifications;
    },
    getSlots: state => {
      return state.slots;
    },
    getStores: state => {
      return state.stores;
    },
    getJoinRequests: state => {
      return state.joinRequests;
    }
  },
  mutations: {
    SET_ACCESS_TOKEN: (state, token) => {
      state.access_token = token;
    },
    SET_REFRESH_TOKEN: (state, token) => {
      state.refresh_token = token;
    },
    SET_USER: (state, user) => {
      state.user = user;
    },
    SET_USER_IS_FOODSHARING_CONNECTED: (state, isFoodsharingConnected) => {
      state.user.is_foodsharing_connected = isFoodsharingConnected;
    },
    RESET: state => {
      Object.assign(state, getDefaultState());
      Axios.defaults.headers.common['Authorization'] = '';
    },
    SET_NOTIFICATIONS: (state, notifications) => {
      state.notifications = notifications;
    },
    SET_SLOTS: (state, slots) => {
      state.slots = slots;
    },
    SET_STORES: (state, stores) => {
      state.stores = stores;
    },
    SET_JOIN_REQUESTS: (state, joinRequests) => {
      state.joinRequests = joinRequests;
    }
  },
  actions: {
    login: ({ commit }, { access_token, refresh_token }) => {
      commit('SET_ACCESS_TOKEN', access_token);
      commit('SET_REFRESH_TOKEN', refresh_token);
      // set auth header
      Axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
    },
    logout: ({ commit }) => {
      console.log("logging out")
      commit('RESET', '');
      router.push('/login');
    },
    setUser: ({ commit }, { user }) => {
      commit('SET_USER', user);
    },
    setUserIsFoodsharingConnected: ({ commit }, { isConnected }) => {
      commit('SET_USER_IS_FOODSHARING_CONNECTED', isConnected);
    },
    setNotifications: ({ commit }, { notifications }) => {
      commit('SET_NOTIFICATIONS', notifications);
    },
    setSlots: ({ commit }, { slots }) => {
      commit('SET_SLOTS', slots);
    },
    setStores: ({ commit }, { stores }) => {
      commit('SET_STORES', stores);
    },
    setJoinRequests: ({ commit }, { joinRequests }) => {
      commit('SET_JOIN_REQUESTS', joinRequests);
    }
  },
  modules:{}
})
