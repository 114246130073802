import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
import axios from 'axios'
import VueAxios from 'vue-axios'


// set auth header
axios.defaults.headers.common['Authorization'] = `Bearer ${store.state.access_token}`;

createApp(App).use(Quasar, quasarUserOptions).use(store).use(router).use(VueAxios, axios).mount('#app')
