<template>
  <div id="q-app">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'LayoutDefault',

  components: {
  },

  setup () {
    return {
    }
  }
}
</script>
