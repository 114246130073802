import AuthService from './AuthService.js';
import store from '../store';

const url = 'https://n8n.mokosch.eu/webhook/';

export default {
  async setStoreName(id) {
    await fetch(url + "slotification-set-store-name?id=" + id);

    //reload store names
    let stores = await AuthService.getStores();
    stores = stores.data;
    store.dispatch('setStores', { stores });
  },
  async updateTwitterId(userId) {
    fetch(url + "slotification-update-twitter-id?id=" + userId);

  },
  async checkFoodsharingCredentials(foodsharingEmail, accountUUID) {
    let response = await fetch(url + "slotification-check-foodsharing-credentials?email=" + foodsharingEmail + "&uuid=" + accountUUID).catch(error => {
      return error.response;
    });
    return response.status === 200;
  }
}