<template>
<default-layout>
  <q-page padding>
    <q-table
      class="home-table"
      title="Benachrichtigungen"
      no-data-label="Keine Benachrichtigungen eingerichtet."
      :rows="getFullRows"
      :columns="columns"
      row-key="id"
      :rows-per-page-options="[0]"
      :pagination="pagination"
      @row-click="openEditDialog"
    />

    <q-page-sticky position="bottom-right" :offset="[18, 18]">
      <q-btn fab icon="add" color="primary" label="Benachrichtigung" @click="openNewDialog"/>
    </q-page-sticky>
  </q-page>

  <q-dialog v-model="showDialog" persistent transition-show="scale" transition-hide="scale">
    <q-card style="width: 700px; max-width: 80vw;">
      <q-card-section class="bg-primary text-white">
        <div style="text-align: center; font-size: 14pt">
          <span v-if="isNewNotification">neue Benachrichtigung</span>
          <span v-else>Benachrichtigung bearbeiten</span>
        </div>
      </q-card-section>

      <q-card-section class="q-gutter-md">
        <q-input outlined v-model="store" type="number" label="Store ID"/>
        <q-select outlined v-model="notificationChannel" :options="notificationChannelList" label="Benachrichtigungsart" />
        <q-input filled v-model="startDate" mask="##.##.####" label="Start Datum">
          <template v-slot:append>
            <q-icon name="event" class="cursor-pointer">
              <q-popup-proxy ref="qDateProxy" cover transition-show="scale" transition-hide="scale">
                <q-date v-model="startDate" mask="DD.MM.YYYY" :options="startDateOption">
                  <div class="row items-center justify-end">
                    <q-btn v-close-popup label="Close" color="primary" flat />
                  </div>
                </q-date>
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>
        <q-input filled v-model="endDate" mask="##.##.####" label="End Datum">
          <template v-slot:append>
            <q-icon name="event" class="cursor-pointer">
              <q-popup-proxy ref="qDateProxy" cover transition-show="scale" transition-hide="scale">
                <q-date v-model="endDate" mask="DD.MM.YYYY" :options="endDateOption">
                  <div class="row items-center justify-end">
                    <q-btn v-close-popup label="Close" color="primary" flat />
                  </div>
                </q-date>
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>
        <q-toggle
          class="full-width justify-center"
          v-model="isActive"
          label="Benachrichtigung aktiv:"
          left-label
        />
      </q-card-section>

      <q-card-actions align="right">
        <q-btn v-if="!isNewNotification" flat label="Löschen" color="negative" @click="deleteNotification" />
        <q-space v-if="!isNewNotification"/>
        <q-btn flat label="Abbrechen" color="primary" @click="closeDialog" />
        <q-btn flat label="Speichern" color="primary" @click="saveDialog" />
      </q-card-actions>
      <q-inner-loading :showing="isSaving">
        <q-spinner-cube size="50px" color="primary" />
      </q-inner-loading>
    </q-card>
  </q-dialog>
  </default-layout>
</template>

<script>
import DefaultLayout from './DefaultLayout.vue'
import AuthService from '@/services/AuthService.js';
import HelperServices from '@/services/HelperServices.js';
import {mapGetters} from 'vuex';
import {date} from 'quasar';

const columns = [
  { name: 'name',required: true, label: 'Geschäft', align: 'left', field: 'storeName', sortable: true },
  { name: 'storeId', align: 'center', label: 'Store ID', field: 'storeId', sortable: true },
  { name: 'notificationChannel', label: 'Benachrichtigung', field: 'notificationChannel', sortable: true },
  { name: 'startDate', label: 'Start Datum', field: 'dateStart', format: val => date.formatDate(val, 'DD.MM.YYYY')},
  { name: 'endDate', label: 'End Datum', field: 'dateEnd', format: val => date.formatDate(val, 'DD.MM.YYYY')},
  { name: 'isActive', label: 'Aktiv?', field: 'isActive', format: val => val ? 'Ja':'Nein', sortable: true}
]

export default {
  data() {
    return {
      user: {},
      columns,
      showDialog: false,
      isNewNotification: true,
      store: '',
      notificationChannel: 'email',
      notificationChannelList:[{ label: 'E-Mail', value: 'email' }, { label: 'WhatsApp', value: 'whatsapp' }, { label: 'Twitter', value: 'twitter' }],
      startDate: date.formatDate(Date.now(), 'DD.MM.YYYY'),
      endDate: date.formatDate(new Date(new Date().setFullYear(new Date().getFullYear() + 1)), 'DD.MM.YYYY'),
      isActive: true,
      activeRow: {},
      pagination: {
        sortBy: 'isActive',
        descending: true
      },
      isSaving: false
    };
  },
  components: {
    DefaultLayout
  },
  async created() {
    if (!this.$store.getters.isLoggedIn) {
      this.$store.dispatch('logout',{});
    }
    this.user = this.$store.getters.getUser;
    if (this.user === undefined){
      //get user data
      this.user = await AuthService.getUser();
      this.user = this.user.data;
      this.$store.dispatch('setUser', this.user);
    }

    let notifications = await AuthService.getNotifications();
    notifications = notifications.data;
    this.$store.dispatch('setNotifications', {notifications});

    let stores = await AuthService.getStores();
    stores = stores.data;
    this.$store.dispatch('setStores', {stores});

  },
  methods: {
    logout() {
      this.$store.dispatch('logout');
    },
    // eslint-disable-next-line no-unused-vars
    openEditDialog(evt, row){
      this.activeRow = row;
      this.store = row.storeId;
      this.notificationChannel = row.notificationChannel;
      this.startDate = date.formatDate(row.dateStart, 'DD.MM.YYYY');
      this.endDate = date.formatDate(row.dateEnd, 'DD.MM.YYYY');
      this.isActive = row.isActive;
      this.isNewNotification = false;
      this.showDialog = true;
    },
    openNewDialog(){
      this.isNewNotification = true;
      this.showDialog = true;
    },
    closeDialog() {
      this.store = '';
      this.notificationChannel = 'email';
      this.startDate = date.formatDate(Date.now(), 'DD.MM.YYYY');
      this.endDate = date.formatDate(new Date(new Date().setFullYear(new Date().getFullYear() + 1)), 'DD.MM.YYYY');
      this.isActive = true;
      this.showDialog = false;
      this.isSaving = false;
    },
    async saveDialog() {
      this.isSaving = true;
      const dmyStart = this.startDate.split(".");
      const dmyEnd = this.endDate.split(".");

      //get Store Name for new store
      if (this.stores.filter(obj => obj.id === this.store).length == 0){
        HelperServices.setStoreName(this.store);
      }

      if (this.isNewNotification){
        await AuthService.addNotification(this.store, this.notificationChannel.value, new Date(dmyStart[2], dmyStart[1] - 1, dmyStart[0]), new Date(dmyEnd[2], dmyEnd[1] - 1, dmyEnd[0]), this.isActive);
      }else{
        await AuthService.updateNotification(this.activeRow.id, this.store, this.notificationChannel.value, new Date(dmyStart[2], dmyStart[1] - 1, dmyStart[0]), new Date(dmyEnd[2], dmyEnd[1] - 1, dmyEnd[0]), this.isActive);
      }


      this.reloadNotifications();
      this.closeDialog();
    },
    async deleteNotification(){
      await AuthService.deleteNotification(this.activeRow.id);
      this.reloadNotifications();
      this.closeDialog();
    },
    endDateOption(datum){
      let dmy = this.startDate.split(".");
      return date.getDateDiff(new Date(datum), new Date(dmy[2], dmy[1]-1, dmy[0]), 'days') > 0;
    },
    startDateOption(datum){
      let dmy = this.endDate.split(".");
      return date.getDateDiff(new Date(datum), new Date(dmy[2], dmy[1]-1, dmy[0]), 'days') < 0;
    },
    async reloadNotifications(){
      let notifications = await AuthService.getNotifications();
      notifications = notifications.data;
      this.$store.dispatch('setNotifications', {notifications});
    },
    reloadStores(){
      let stores = AuthService.getStores();
      stores = stores.data;
      this.$store.dispatch('setStores', {stores});
    }
  },
  computed: {
    ...mapGetters({
      rows: 'getNotifications',
      stores: 'getStores'
    }),
    getFullRows: function() {
      let fullRows = JSON.parse(JSON.stringify(this.rows));
      for (let i = 0; i < this.rows.length; i++) {
        fullRows[i].storeName = this.stores.filter(obj => obj.id === fullRows[i].storeId)[0];
        fullRows[i].storeName = fullRows[i].storeName === undefined ? fullRows[i].storeId : fullRows[i].storeName.name;
      }
      return fullRows;
    }
  }
};
</script>
