<template>
  <div class="login-background">
    <q-card class="login-card fixed-center">
      <q-form @submit="onSubmit">
        <q-card-section>
          <p class="title">Slotification</p>
          <p class="subtitle">Passwort Reset</p>
        </q-card-section>
        <q-card-section>
          <q-input
            outlined
            bottom-slots
            v-model="newPassword2"
            :type="isPwd ? 'password' : 'text'"
            label="Passwort"
          >
            <template v-slot:prepend>
              <q-icon name="key" />
            </template>
            <template v-slot:append>
              <q-icon
                :name="isPwd ? 'visibility_off' : 'visibility'"
                class="cursor-pointer"
                @click="isPwd = !isPwd"
              />
            </template>
          </q-input>
          <q-input outlined bottom-slots v-model="newPassword1" label="Passwort wiederholen" type="password">
            <template v-slot:prepend>
              <q-icon name="key" />
            </template>
          </q-input>
          <div>
            *mindestens 8 Zeichen
          </div>
        </q-card-section>
        <q-card-actions class="justify-end">
          <q-btn
            flat
            color="primary"
            type="submit"
            :disabled="newPassword1 != newPassword2 || newPassword1.length < 7"
          >
            Passwort ändern
          </q-btn>
        </q-card-actions>
      </q-form>
    </q-card>
  </div>

</template>

<script>
  import AuthService from '@/services/AuthService.js'
  // @ is an alias to /src

  export default {
    name: 'Login',
    data() {
      return {
        newPassword1: '',
        newPassword2: '',
        isPwd: true,
        dialog: false
      }
    },
    components: {},
    async created() {
      let token = this.$route.query;
      token = token[Object.keys(token)[0]].substring(6)
      //console.log(token)

      if (token.length < 1) {
        this.router.push('/');
      }
    },
    methods: {
      async onSubmit() {
        try {
          let token = this.$route.query;
          token = token[Object.keys(token)[0]];

          if (token.length < 1){
            alert("Fehler beim Ändern des Passworts.")
            return;
          }
          const payload = {
            password: this.newPassword1,
            token: token
          }

          /*const response = */await AuthService.setPassword(payload);

          this.logout();
        } catch (error) {
          console.log(error)
        }
      },
      logout(){
       this.$store.dispatch('logout',{});
      }
    },
  }
</script>
