<template>
	<q-dialog
		v-model="show"
		persistent
		transition-show="scale"
		transition-hide="scale"
	>
		<q-card class="bg-grey-2" style="width: 700px; max-width: 80vw;">
			<q-card-section class="row items-center bg-primary text-white">
				<div class="text-h6">{{title}}</div>
			</q-card-section>
			<q-card-section>
				<slot></slot>
			</q-card-section>
			<q-card-actions align="right">
				<q-btn v-if="hasDelete" flat label="Löschen" color="negative" @click="deleteClicked" />
        <q-space v-if="hasDelete"/>
				<q-btn
					flat
					label="Abbrechen"
					color="primary"
					@click="abortClicked"
				/>
				<q-btn
					flat
					label="Speichern"
					color="primary"
					@click="saveClicked"
				/>
			</q-card-actions>

			<q-inner-loading :showing="isSaving">
        <q-spinner-cube size="50px" color="primary" />
      </q-inner-loading>
		</q-card>
	</q-dialog>
</template>

<script>

export default {
	name: 'DialogBase',
	props: {
		title: {
			type: String,
			default: 'Dialog'
		},
		hasDelete: {
			type: Boolean,
			default: false
		},
		showDialog: {
			type: Boolean,
			default: false
		}
	},

	emits: ['update:showDialog', 'clicked:save', 'clicked:abort', 'clicked:delete'],

	data() {
		return {
			isSaving: false
		};
	},
	methods: {
		saveClicked() {
			this.isSaving = true;
			this.$emit('clicked:save');
		},
		abortClicked() {
			this.show = false;
			this.$emit('clicked:abort');
		},
		deleteClicked() {
			this.isSaving = true;
			this.$emit('clicked:delete');
		}
	},
	computed: {
    show: {
      get () {
        return this.showDialog
      },
      set (showDialog) {
				this.$emit('update:showDialog', showDialog)
      }
    }
  }
};
</script>
