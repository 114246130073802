import axios from 'axios';
import store from '../store';

const url = 'https://api.mokosch.eu/slotification/';

export default {
  async pingServer() {
    try {
      return axios.get(url + 'server/ping').then((response) => {
        return response.data === "pong"
      }).catch(error => {
        console.log(error);
      });
    } catch (error) {
      return false;
    }

  },
  async login(credentials) {
    const response = await axios
      .post(url + 'auth/login/', credentials);
    return response.data;
  },
  /*signUp(credentials) {
    return axios
      .post(url + 'sign-up/', credentials)
      .then(response => response.data);
  },*/
  checkLogin() {
    const response = axios.get(url + 'users/me').then(response => response.data).catch(error => { console.log(error); });

    return !(response === undefined);
  },
  async getUser() {
    try {
      const response = await axios.get(url + 'users/me');
      return response.data;
    } catch (error) {
      console.log(error);
      store.dispatch('logout', {});
    }
  },

  async getJoinRequests() {
    try {
      const response = await axios.get(url + 'items/Join_Requests');
      return response.data;
    } catch (error) {
      console.log(error);
      store.dispatch('logout', {});
    }
  },

  async updateJoinRequest(joinRequest) {
    try {
      const id = joinRequest.id;
      delete joinRequest.id;
      const response = await axios.patch(url + 'items/Join_Requests/' + id, joinRequest);
      return response.data;
    } catch (error) {
      console.log(error);
      store.dispatch('logout', {});
    }
  },

  async deleteJoinRequest(id) {
    try {
      const response = await axios.delete(url + 'items/Join_Requests/' + id);
      return response.data;
    } catch (error) {
      console.log(error);
      store.dispatch('logout', {});
    }
  },

  async addJoinRequest(joinRequest) {
    try {
      delete joinRequest.id;
      const response = await axios.post(url + 'items/Join_Requests', joinRequest);
      return response.data;
    } catch (error) {
      console.log(error);
      store.dispatch('logout', {});
    }
  },
  

  async getNotifications() {
    try {
      const response = await axios.get(url + 'items/Notifications2');
      return response.data;
    } catch (error) {
      console.log(error);
      store.dispatch('logout', {});
    }
  },
  addNotification(storeId, notificationChannel, startDate, endDate, isActive) {
    const response = axios.post(url + 'items/Notifications2', { storeId: storeId, notificationChannel: notificationChannel, dateStart: startDate, dateEnd: endDate, isActive: isActive }).then(response => response.data);
    if (response !== undefined) {
      return response;
    } else {
      store.dispatch('logout', {});
      return {};
    }
  },
  updateNotification(id, storeId, notificationChannel, startDate, endDate, isActive) {
    const response = axios.patch(url + 'items/Notifications2/' + id, { storeId: storeId, notificationChannel: notificationChannel, dateStart: startDate, dateEnd: endDate, isActive: isActive }).then(response => response.data);
    if (response !== undefined) {
      return response;
    } else {
      store.dispatch('logout', {});
      return {};
    }
  },
  deleteNotification(id) {
    const response = axios.delete(url + 'items/Notifications2/' + id).then(response => response.data);
    if (response !== undefined) {
      return response;
    } else {
      store.dispatch('logout', {});
      return {};
    }
  },
  async updateUser(id, payload) {
    const response = await axios.patch(url + 'users/' + id, payload).then((response) => {return response.data}).catch(error => {return error.response})
    if (response !== undefined && response.status !== 401) {
      return true;
    } else {
      store.dispatch('logout', {});
      return false;
    }
  },
  async getStores() {
    try {
      const response = await axios.get(url + 'items/Stores');
      return response.data;
    } catch (error) {
      console.log(error);
      store.dispatch('logout', {});
    }
  },
  async getSlots() {
    try {
      const response = await axios.get(url + 'items/Slots');
      return response.data;
    } catch (error) {
      console.log(error);
      store.dispatch('logout', {});
    }
  },
  async setPassword(payload) {
    axios.defaults.headers.common['Authorization'] = '';
    try {
      const response = await axios.post(url + 'auth/password/reset', payload);
      return response.data;
    } catch (error) {
      console.log(error);
      store.dispatch('logout', {});
    }
  },
  async requestPassword(email) {
    try {
      const response = await axios.post(url + 'auth/password/request', { email: email, reset_url: 'slotification.mokosch.eu/password' });
      return response.data;
    } catch (error) {
      console.log(error);
      store.dispatch('logout', {});
    }
  }
};