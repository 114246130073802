<template>
  <default-layout>
    <q-page padding>
      <q-table
        class="home-table"
        title="Automatisches Eintragen"
        no-data-label="Keine Slots für automatisches Eintragen eingerichtet."
        :rows="getFullRows"
        :columns="columns"
        row-key="id"
        :rows-per-page-options="[0]"
        :pagination="pagination"
        @row-click="openEditDialog"
      />

      <q-banner v-if="!user.is_foodsharing_connected" dense inline-actions class="text-white bg-red q-my-md" style="border-radius: 1em">
      Automatisches Eintragen funktioniert nur, wenn du deinen Foodsharing Account verbunden hast.
      <template v-slot:action>
        <q-btn flat color="white" label="jetzt verbinden" :to="{path: '/account'}"/>
      </template>
    </q-banner>

      <p class="q-py-md text-body2 text-weight-light">
        *Automatisches Eintragen bedeutet, dass du automatisch für einen Slot eingetragen wirst, sobald dieser verfügbar ist.<br>
         Der Join Request wird automatisch deaktiviert, sobald du für einen Slot eingetragen wurdest. Du musst ihn danach manuell wieder aktivieren.
      </p>

      <q-page-sticky position="bottom-right" :offset="[18, 18]">
        <q-btn fab icon="add" color="primary" label="Join Request" @click="openAddDialog"/>
      </q-page-sticky>

      <join-request-dialog
        v-if="activeRow"
        :join-request="activeRow"
        :showDialog="showDialog"
        @update:showDialog="showDialog = $event"
        @clicked:save="saveRequest($event)"
        @clicked:delete="deleteRequest(activeRow)"
        @clicked:abort="abortClicked"/>
    </q-page>
  </default-layout>
</template>

<script>
import DefaultLayout from './DefaultLayout.vue';
import JoinRequestDialog from './dialogs/JoinRequestDialog.vue';
import AuthService from '@/services/AuthService.js';
import {mapGetters} from 'vuex';
import {date} from 'quasar'

const columns = [
  { name: 'name',required: true, label: 'Geschäft', align: 'left', field: 'storeName', sortable: true },
  { name: 'storeId', align: 'center', label: 'Store ID', field: 'storeId', sortable: true },
  { name: 'startDate', label: 'Start Datum', field: 'pickup_date_start', format: (val,row) => {
    return row.is_pickup_date_weekday ? new Array("So","Mo","Di","Mi","Do","Fr","Sa")[new Date(val).getDay()] : date.formatDate(val, 'DD.MM.YYYY')}},
  { name: 'endDate', label: 'End Datum', field: 'pickup_date_end', format: (val,row) => {
    return row.is_pickup_date_weekday ? new Array("So","Mo","Di","Mi","Do","Fr","Sa")[new Date(val).getDay()] : date.formatDate(val, 'DD.MM.YYYY')}},
  { name: 'isActive', label: 'Aktiv?', field: 'is_active', format: val => val ? 'Ja':'Nein', sortable: true}
]

export default {
  data() {
    return {
      user: {},
      store: '',
      columns,
      showDialog: false,
      isNewJoinRequest: true,
      activeRow: null,
      pagination: {
        sortBy: 'isActive',
        descending: true
      },
      weekday: ["So","Mo","Di","Mi","Do","Fr","Sa"],
    };
  },
  components: {
    DefaultLayout,
    JoinRequestDialog
  },
  async created() {
    if (!this.$store.getters.isLoggedIn) {
      this.$store.dispatch('logout',{});
    }
    this.user = this.$store.getters.getUser;
    if (this.user === undefined){
      //get user data
      this.user = await AuthService.getUser();
      this.user = this.user.data;
      this.$store.dispatch('setUser', this.user);
    }

    if(this.$store.getters.getJoinRequests.length === 0){
      this.reloadJoinRequests();
    }

    if(this.$store.getters.getStores.length === 0){
      this.reloadStores();
    }
  },

  methods: {
    logout() {
      this.$store.dispatch('logout');
    },
    async reloadJoinRequests(){
      let joinRequests = await AuthService.getJoinRequests();
      joinRequests = joinRequests.data;
      this.$store.dispatch('setJoinRequests', {joinRequests});
    },
    async reloadStores(){
      let stores = await AuthService.getStores();
      stores = stores.data;
      this.$store.dispatch('setStores', {stores});
    },
    abortClicked(){
      this.activeRow = null;
      this.isNewJoinRequest = true;
    },
    async saveRequest(joinRequest){
      if (this.isNewJoinRequest){
        await AuthService.addJoinRequest(joinRequest);
      } else {
        await AuthService.updateJoinRequest(joinRequest);
      }
      this.showDialog = false;
      this.activeRow = null;
      this.reloadJoinRequests();
    },
    async deleteRequest(joinRequest){
      await AuthService.deleteJoinRequest(joinRequest.id);
      this.showDialog = false;
      this.isNewJoinRequest = true;
      this.activeRow = null;
      this.reloadJoinRequests();
    },
    openEditDialog(event, row){
      this.isNewJoinRequest = false;
      this.activeRow = row;
      this.showDialog = true;
    },
    openAddDialog(){
      this.activeRow = {};
      this.showDialog = true;
    }
  },
  computed: {
    ...mapGetters({
      rows: 'getJoinRequests',
      stores: 'getStores'
    }),
    getFullRows: function() {
      let fullRows = JSON.parse(JSON.stringify(this.rows));
      for (let i = 0; i < this.rows.length; i++) {
        fullRows[i].storeName = this.stores.filter(obj => obj.id === fullRows[i].storeId)[0];
        fullRows[i].storeName = fullRows[i].storeName === undefined ? fullRows[i].storeId : fullRows[i].storeName.name;
      }
      return fullRows;
    }
  }
};
</script>
