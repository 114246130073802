<!--Write a Vue Card Component which allows to edit mail and password for the foodsharing account and checks if the provided credentials are correct-->
<template>
	<q-card>
		<q-card-section>
			<div class="row">
				<div class="col-4 flex items-center">
					<q-avatar size="48px" class="q-mx-lg" icon="fas fa-seedling" :text-color="user.is_foodsharing_connected == 1 ? 'primary' : 'negative'"/>
				</div>
				<div class="col-8">
					<div class="account-card-text">Foodsharing Account</div>
					<div v-if="!editMode">
						<div class="">E-Mail: {{user.foodsharing_email}}</div>
						<div class="flex items-center">Status: {{user.is_foodsharing_connected == 1 ? 'verbunden' : 'nicht verbunden'}}
							<q-btn v-if="!checkingCredentials" flat icon="fas fa-sync" size="xs" color="primary" @click="$emit('reload-request')"/>
							<q-spinner class="q-mx-md" v-else size="xs" color="primary" />
						</div>
					</div>
					<div v-else class="col-8 q-gutter-md">
						<q-input dense v-model="email" type="email" label="E-Mail"/>
						<q-input dense v-model="password" type="password" label="Passwort"/>
					</div>
				</div>
			</div>
		</q-card-section>

		<q-separator />
		<q-card-actions vertical>
			<q-btn v-if="!editMode" flat label="Bearbeiten"  @click="editMode = true" />
			<div v-else class="row justify-between full-width">
				<q-btn flat label="Abbrechen"  @click="onReset" />
				<q-btn flat label="Speichern"  @click="onSubmit" :disabled="password.length < 2 || email.length < 5 || !email.includes('@')"/>
			</div>
		</q-card-actions>
	</q-card>
</template>


<script>
export default {
	props: {
    user: {
      type: Object,
      required: true
    },
		checkingCredentials: {
			type: Boolean,
			required: false,
			default: false,
		},
  },

	emits: {
		'update': null,
		'reload-request': null,
	},

	data() {
		return {
			email: '',
			password: '',
			editMode: false,
		};
	},
	created() {
		this.email = this.user.foodsharing_email;
	},
	methods: {
		onSubmit() {
			//show error at input if email or password is empty
			let payload = {};
			if (this.password.length > 1){
				payload.foodsharing_password = this.password;
			}
			if(this.email !== this.user.foodsharing_email){
				payload.foodsharing_email = this.email;
			}
			this.$emit('update', payload);
			this.password = '';
			this.editMode = false;
		},
		onReset() {
			this.email = this.user.foodsharing_email;
			this.editMode = false;
		},
	},
}
</script>