<template>
  <default-layout>
    <q-page padding>
      <q-table
        class="home-table"
        title="Freie Slots aller beobachteten Betriebe"
        no-data-label="Keiner der beobachteten Betriebe verfügt über freie Slots."
        :rows-per-page-label="'Slots pro Seite'"
        :rows="getFullRows"
        :columns="columns"
        row-key="id"
        :rows-per-page-options="[10,20,50,100]"
        :pagination="pagination"
        @row-click="openExternalURL"
      />
    </q-page>
  </default-layout>
</template>

<script>
import DefaultLayout from './DefaultLayout.vue'
import AuthService from '@/services/AuthService.js';
import {mapGetters} from 'vuex';
import {date, openURL} from 'quasar';

const columns = [
  { name: 'name',required: true, label: 'Geschäft', align: 'left', field: 'storeName', sortable: true },
  { name: 'pickupDate', label: 'Datum', field: 'pickupDate', format: val => date.formatDate(new Date(val.slice(0,23)), 'ddd DD.MM.YYYY',{daysShort:["So","Mo","Di","Mi","Do","Fr","Sa"]}), align: 'left', sortable: true},
  { name: 'pickupTime', label: 'Uhrzeit', field: 'pickupDate', format: val => date.formatDate(new Date(val.slice(0,23)), 'HH:mm'), align: 'left', sortable: true},
  { name: 'storeId', align: 'left', label: 'Store ID', field: 'storeId', sortable: true}
]

export default {
  data() {
    return {
      user: {},
      columns,
      store: '',
      pagination: {
        sortBy: 'pickupDate',
        descending: false,
        rowsPerPage: 20
      },
    };
  },
  components: {
    DefaultLayout
  },
  async created() {
    if (!this.$store.getters.isLoggedIn) {
      this.$store.dispatch('logout',{});
    }
    this.user = this.$store.getters.getUser;
    if (this.user === undefined){
      //get user data
      this.user = await AuthService.getUser();
      this.user = this.user.data;
      this.$store.dispatch('setUser', this.user);
    }

    let slots = await AuthService.getSlots();
    slots = slots.data;
    this.$store.dispatch('setSlots', {slots});

    let stores = await AuthService.getStores();
    stores = stores.data;
    this.$store.dispatch('setStores', {stores});

  },
  methods: {
    logout() {
      this.$store.dispatch('logout');
    },
    async reloadSlots(){
      let slots = await AuthService.getSlots();
      slots = slots.data;
      this.$store.dispatch('setSlots', {slots});
    },
    reloadStores(){
      let stores = AuthService.getStores();
      stores = stores.data;
      this.$store.dispatch('setStores', {stores});
    },
    openExternalURL(evt, row){
      openURL('https://foodsharing.de/?page=fsbetrieb&id='+row.storeId)
    }
  },
  computed: {
    ...mapGetters({
      rows: 'getSlots',
      stores: 'getStores'
    }),
    getFullRows: function() {
      let fullRows = JSON.parse(JSON.stringify(this.rows));
      for (let i = 0; i < this.rows.length; i++) {
        fullRows[i].storeName = this.stores.filter(obj => obj.id === fullRows[i].storeId)[0];
        fullRows[i].storeName = fullRows[i].storeName === undefined ? fullRows[i].storeId : fullRows[i].storeName.name;
      }
      return fullRows;
    }
  }
};
</script>
